<template>
  <div class="my-5">
    <ul>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=181"
          >2 wheeler Loan for ACTIVA 6G DLX BS VI</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=39"
          >2 wheeler Loan for HONDA CD 110 DREAM SELF START</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=49"
          >2 wheeler Loan for HONDA HNESS CB350 DLX</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=70"
          >2 wheeler Loan for HONDA CBR250R REPSOL (2011-2017)</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=91"
          >2 wheeler Loan for HONDA CB 125 F</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=114"
          >2 wheeler Loan for HONDA GRAZIA</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=117"
          >2 wheeler Loan for XBLADE</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=129"
          >2 wheeler Loan for SP 125 DRUM BS VI</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=137"
          >2 wheeler Loan for DIO</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=139"
          >2 wheeler Loan for SHINE</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=141"
          >2 wheeler Loan for UNICORN</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=151"
          >2 wheeler Loan for aVO</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=154"
          >2 wheeler Loan for HORNET</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=155"
          >2 wheeler Loan for NAVI</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=163"
          >2 wheeler Loan for PLEASURE PLUS FI BS6 ALLOY WHEEL</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=169"
          >2 wheeler Loan for HF DELUXE BS6 SELFSTART ALLOY WHEEL</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=188"
          >2 wheeler Loan for HERO SUPER SPLENDOR DISC BS6</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=194"
          >2 wheeler Loan for HERO SPLENDOR ISMART BS6 DRUM</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=220"
          >2 wheeler Loan for HERO MOTOCORP XTREME SINGLE DISC</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=231"
          >2 wheeler Loan for HERO MAESTRO EDGE 125 DISC BS6</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=232"
          >2 wheeler Loan for PASSION PRO 110 BS6 DRUM</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=272"
          >2 wheeler Loan for SPLENDOR PLUS BS VI I3S</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=273"
          >2 wheeler Loan for GLAMOUR 125 FI BS6 DISC</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=283"
          >2 wheeler Loan for DESTINI 125 LX</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=289"
          >2 wheeler Loan for XTREME 200S</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=355"
          >2 wheeler Loan for TVS STAR CITY PLUS DUAL TONE BS6</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=359"
          >2 wheeler Loan for PEP PLUS MATTE SERIES</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=365"
          >2 wheeler Loan for TVS SPORT KICK START LONG SEAT BS6</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=373"
          >2 wheeler Loan for TVS SCOOTY ZEST SE</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=384"
          >2 wheeler Loan for TVS ENTORQ 210 STD</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=391"
          >2 wheeler Loan for APACHE RR</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=424&&type=Scooter&&mfg=4"
          >2 wheeler Loan for FASCINO DRUM LTD ED BS VI</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=437&&type=Scooter&&mfg=4"
          >2 wheeler Loan for RAY ZR DRUM UBS BS VI</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=446&&type=Bike&&mfg=4"
          >2 wheeler Loan for YAMAHA R25 250</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=447&&type=Bike&&mfg=4"
          >2 wheeler Loan for YAMAHA FAZER 25 ABS</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=475&&type=Bike&&mfg=4"
          >2 wheeler Loan for YAMAHA MT-15 BS6 METALaC BLACK</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=476&&type=Bike&&mfg=4"
          >2 wheeler Loan for YAMAHA FZS STD</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=496&&type=Bike&&mfg=4"
          >2 wheeler Loan for MT 15</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=504&&type=Bike&&mfg=4"
          >2 wheeler Loan for YZF R15 VER2</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=534&&type=Bike&&mfg=5"
          >2 wheeler Loan for BAJAJ AVENGER CRUISE 220 BS6</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=540&&type=Bike&&mfg=5"
          >2 wheeler Loan for BAJAJ PLATINA 100 KS ALLOY BS6</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=542&&type=Bike&&mfg=5"
          >2 wheeler Loan for BAJAJ PULSAR 150 BS6</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=550&&type=Bike&&mfg=5"
          >2 wheeler Loan for BAJAJ DOMINAR 400 BS6</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=574&&type=Bike&&mfg=5"
          >2 wheeler Loan for BAJAJ CT 100 KS SPOKE CBS</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=581&&type=Bike&&mfg=5"
          >2 wheeler Loan for BAJAJ AVENGER STREET 160 BS6</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=587&&type=Bike&&mfg=5"
          >2 wheeler Loan for BAJAJ PULSAR 180F BS6</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=589&&type=Bike&&mfg=5"
          >2 wheeler Loan for BAJAJ PULSAR 220 F BS6</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=590&&type=Bike&&mfg=5"
          >2 wheeler Loan for BAJAJ PULSAR NS200 BS6</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=621&&type=Bike&&mfg=5"
          >2 wheeler Loan for PULSAR 160</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=634&&type=Bike&&mfg=6"
          >2 wheeler Loan for CLASSIC 500 BATTLE GREEN</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=640&&type=Bike&&mfg=6"
          >2 wheeler Loan for METEOR</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=663&&type=Bike&&mfg=6"
          >2 wheeler Loan for BS6 ROYAL ENFIELD CLASSIC 350 BLACK</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=665&&type=Bike&&mfg=6"
          >2 wheeler Loan for HIMALAYAN BS6 SNOW WHITE</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=679&&type=Bike&&mfg=6"
          >2 wheeler Loan for CLASSIC 350</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=681&&type=Bike&&mfg=6"
          >2 wheeler Loan for THUNDERBIRD</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=686&&type=Bike&&mfg=6"
          >2 wheeler Loan for INTERCEPTOR</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=687&&type=Bike&&mfg=6"
          >2 wheeler Loan for BULLET</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=693&&type=Bike&&mfg=6"
          >2 wheeler Loan for BULLET</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=9151"
          >2 wheeler Loan for SUZUKI BURGMAN STREET BLUETOOTH</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=9261"
          >2 wheeler Loan for SUZUKI ACCESS 125 SE</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=9411"
          >2 wheeler Loan for SUZUKI INTRUDER</a
        >
      </li>
      <li>
        <a
          class="text-overline"
          style="text-decoration:none"
          href="https://ontrack-finance.web.app/#/finance?id=9641"
          >2 wheeler Loan for SUZUKI GIXXER</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "Sitemap"
};
</script>
<style scoped>
a {
  margin: 10px;
  font-family: Impact;
  font-size: 16px;
  text-align: center;
}
</style>
